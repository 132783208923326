<template>
  <search-form :items="items" @fnSearch="handleFnSearch" />
  <grid
    ref="gridRef"
    allow-selection
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :btn-operation="btnOperation"
    :scroll="{ x: 1400, y: gridHeight }"
  >
    <template #avatar="{ text, record }">
      <a-avatar v-if="text" :src="setAvatar(text)" size="large" />
      <a-avatar v-else size="large" class="iss-avatar">
        {{ record.name }}
      </a-avatar>
    </template>
    <template #sex="{ text, record }">
      <a-tag :color="text === 'M' ? 'blue' : text === 'W' ? 'orange' : ''">
        {{ record.sex.desc }}
      </a-tag>
    </template>
    <template #status="{ text }">
      <a-tag :color="text ? 'success' : 'error'">
        {{ text ? '启用' : '禁用' }}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
  <transfer-modal
    v-model:visible="roleVisible"
    :url="roleUrl"
    :leftColumns="leftColumns"
    :rightColumns="rightColumns"
    :init-value="activeRoles"
    :filter-option="
      (inputValue, { code, name }) =>
        code.indexOf(inputValue) !== -1 || name.indexOf(inputValue) !== -1
    "
    @fnOk="handleFnOkByRole"
  />
  <iss-user-view v-model:visible="viewVisible" :init-value="activeItem" />
  <iss-user-edit
    v-model:visible="editVisible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Avatar, message, Tag } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import TransferModal from '@/components/transferModal';
import IssUserView from './components/view';
import IssUserEdit from './components/edit';
import { setAvatar } from '@/utils';
import orgApi from '@/api/org';
import roleApi from '@/api/role';
import userApi from '@/api/user';

export default {
  components: {
    AAvatar: Avatar,
    ATag: Tag,
    SearchForm,
    Grid,
    Operation,
    TransferModal,
    IssUserView,
    IssUserEdit,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const gridRef = ref();
    const state = reactive({
      search: {},
      selectedRowKeys: [],
      viewVisible: false,
      editVisible: false,
      roleVisible: false,
      activeItem: {},
      activeRoles: [],
    });
    const deleteItems = ids => {
      if (ids.includes(store.state.account.user.userId)) {
        message.warning('当前删除项中存在当前登录用户，不能删除');
      } else {
        userApi.delete('user:delete', { ids }).then(() => {
          message.success('操作成功');
          gridRef.value.refreshGrid();
        });
      }
    };
    return {
      items: [
        { key: 'name', label: '名称' },
        {
          key: 'orgId',
          label: '组织',
          type: 'treeSelect',
          code: route.path,
          url: orgApi.listUrl,
          options: { replaceFields: { key: 'id', value: 'id' } },
        },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 80,
          slots: { customRender: 'avatar' },
          // customCell: () => ({ style: 'padding: 8px' }),
        },
        { dataIndex: 'name', title: '名称' },
        {
          dataIndex: 'sex.code',
          title: '性别',
          width: 80,
          slots: { customRender: 'sex' },
        },
        { dataIndex: 'mobile', title: '手机', width: 150 },
        { dataIndex: 'email', title: '邮箱' },
        { dataIndex: 'orgName', title: '组织' },
        { dataIndex: 'stationName', title: '岗位' },
        {
          dataIndex: 'status',
          title: '状态',
          width: 80,
          slots: { customRender: 'status' },
        },
        {
          dataIndex: 'id',
          fixed: 'right',
          title: '操作',
          width: 170,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'user:add',
          fnClick: () => {
            state.editVisible = true;
            state.activeItem = {};
          },
        },
        {
          type: 'delete',
          permission: 'user:delete',
          fnClick: deleteItems,
        },
      ],
      options: [
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'user:view',
          fnClick: record => {
            state.viewVisible = true;
            state.activeItem = record;
          },
        },
        {
          type: 'update',
          permission: 'user:update',
          fnClick: record => {
            state.editVisible = true;
            state.activeItem = { ...record };
          },
        },
        {
          type: 'role',
          label: '角色',
          icon: 'SettingTwoTone',
          permission: 'user:role',
          fnClick: record => {
            state.activeItem = record;
            roleApi.getRoleIdByUserId('user:role', record.id).then(data => {
              state.roleVisible = true;
              state.activeRoles = data;
            });
          },
        },
        {
          type: 'delete',
          permission: 'user:delete',
          fnClick: ({ id }) => deleteItems([id]),
        },
      ],
      leftColumns: [
        { dataIndex: 'code', title: '编码' },
        { dataIndex: 'name', title: '名称' },
        { dataIndex: 'describe', title: '描述' },
      ],
      rightColumns: [
        { dataIndex: 'code', title: '编码' },
        { dataIndex: 'name', title: '名称' },
      ],
      url: userApi.pageUrl,
      roleUrl: `${roleApi.pageUrl}?size=100000&status=true`,
      gridHeight: document.body.clientHeight - 323,
      gridRef,
      ...toRefs(state),
      setAvatar,
      handleFnSearch: value => {
        state.search = value;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        value.id && (type = 'update');
        userApi[type](`user:${type}`, value).then(() => {
          message.success('操作成功');
          gridRef.value.refreshGrid();
        });
      },
      handleFnOkByRole: roleIdList => {
        roleApi
          .saveRoleUser('user:role', {
            type: 2,
            userIdList: [state.activeItem.id],
            roleIdList,
          })
          .then(() => message.success('操作成功'));
      },
    };
  },
};
</script>

<style lang="less" scoped></style>
